<template lang="pug">
  .st-container(:class="selectedSize.class")
    v-card
      v-card-content(v-if="!loaded")
        v-skeleton(
            width="100%"
            height="50vh")
      template(v-else)
          .matrix-empty(
            v-if="isMatrixEmpty")
            h2.title {{ 'ui.labels.matrix_without_skills' | translate }}
          .matrix(v-else)
            .matrix-row
              .matrix-column
                .matrix-title.p-24-24-8-24
                      icon-matrix
                      h1 {{ matrixDetail.info.name }}
                .matrix-cell.matrix-cell--center.p-24.bd-bottom.row-height--one
                      v-btn.matrix-btn--icon(
                        paddingless
                        text
                        @click="swapRowsCols")
                        icon-swap
                        span {{ 'pages.skill_gap.swap_rows_columns' | translate }}
                .matrix-cell.matrix-cell--column.w-100.bd-bottom(:class="[isSwappedRowCols ? 'p-32': 'p-20-24']")
                  template(v-for="(item, index) in sideLabels")
                    .employee-item--horizontal(v-if="item.type" :key="`${item.id}_side`")
                      .employee-user
                        .flex-column
                          .employee-user--name {{ item.title }}
                          .employee-user--position {{ item.position ? item.position.name : '' }}
                        v-avatar(
                          :size="40"
                          :src="item.profilePictureUrl")
                    .matrix-item.matrix-item--horizontal(v-else :key="`${item.id}_side`")
                        v-flipable
                          icon-badge.m-0.badge-item--icon
                          span.badge-item--title {{ item.title }}
                .matrix-cell.w-100
                  .matrix-cell.bd-right.p-20
                      v-btn.matrix-btn--icon(
                        text
                        reset
                        @click="isViewSwapped = !isViewSwapped")
                        v-flipable(flip)
                          icon-swap
                          span {{ 'ui.buttons.swap_view' | translate }}
                  .matrix-cell.matrix-cell--column.p-32.w-100
                    .matrix-item.matrix-item--horizontal.m-32
                      span.matrix-label.gray {{ (isViewSwapped ? 'pages.skill_gap.achieved_skills' : 'pages.skill_gap.achieved_levels') | translate }}
                    .matrix-item.matrix-item--horizontal.m-32
                      span.matrix-label.orange {{ (isViewSwapped ? 'pages.skill_gap.required_skills' : 'pages.skill_gap.required_levels') | translate }}
                    .matrix-item.matrix-item--horizontal.m-32
                      span.matrix-label {{ (isViewSwapped ? 'pages.skill_gap.skill_gap' : 'pages.skill_gap.level_gap') | translate }}
                      v-info-icon.m-0(:text="(isViewSwapped ? 'pages.skill_gap.skill_gap_description' : 'pages.skill_gap.level_gap_description') | translate")
              //- colum # 2
              .matrix-column
                  .matrix-scrollable#matrix-table.h-100
                      .matrix-cell.h-400.bd-bottom#matrix-header.w-fc(:class="[isSwappedRowCols ? 'p-20-24' :  'p-32', {'matrix-cell--center': isCenterContent}]")
                        template(
                          v-for="item in topLabels")
                          .matrix-item.matrix-item--vertical(v-if="!item.type" :key="`${item.id}_top`")
                            v-flipable(flip)
                              icon-badge.m-0.badge-item--icon
                              span.badge-item--title(v-tooltip="renderTooltip(item.title, 45)") {{ item.title | truncateText(45) }}
                          .employee-item.employee-item--vertical(v-else :key="`${item.id}_top`")
                              v-flipable(flip).employee-user
                                  v-avatar(:size="40" :src="item.profilePictureUrl")
                                  .flex.text-left
                                    span.employee-user--name {{ item.title }}
                                    span.employee-item--position {{ item.position ? item.position.name : '' }}
                      .matrix-cell.matrix-cell--column.p-24.bd-bottom#matrix-content.w-fc(:class="{'matrix-cell--center': isCenterContent}")
                        .matrix-row.circle(
                          v-for="(row, index) in matrix"
                          :key="index + Math.random()")
                          .matrix-item(
                            v-for="(badge, idx) in row"
                            :key="idx + Math.random()")
                            v-donut(
                              :size="32"
                              :space="1"
                              :stroke ="2"
                              viewBox="4 4 34 34"
                              :color="badge | color"
                              :iconColor="badge | iconColor"
                              :secondaryColor="badge | secondaryColor"
                              :backgroundColor="badge | backgroundColor"
                              :count="!badge.isRequired ? +badge.maxStarsCount : +badge.requiredStarsCount"
                              :value="+badge.activeStarsCount || +badge.isActive"
                              :color-percent="badge | colorPercent"
                              v-tooltip="getTooltipText(badge, badges[!isSwappedRowCols ? idx : index])")
                              icon-star(v-if="badge.isGoal")
                              icon-close(v-else-if="(+badge.activeStarsCount < (badge.isRequired ? +badge.requiredStarsCount : +badge.maxStarsCount)) || (!(+badge.isActive) && ((badge.isRequired ? +badge.requiredStarsCount : +badge.maxStarsCount) === 0))")
                              icon-check(v-else height='10' width='13')
                      .matrix-cell.p-24(:class="{'matrix-cell--center': isCenterContent}")
                        matrix-gap-bottom-list(
                            :bottomLabels="bottomLabels"
                            :isViewSwapped="isViewSwapped")
              //- colum # 3
              .matrix-column
                  .matrix-cell.bd-bottom(style='height: 56px')
                    .matrix-buttons
                      v-btn.matrix-btn--icon(
                        text
                        paddingless
                        v-tooltip="{placement: 'top', content: $t('ui.buttons.swap_view') }"
                        @click="isViewSwapped = !isViewSwapped")
                        icon-swap
                        span.text-nowrap {{ 'ui.buttons.swap_view' | translate }}
                      v-dropdown(autoClose right widthAuto)
                        v-btn.matrix-btn--icon(
                          text
                          paddingless
                           v-tooltip="{placement: 'top', content: $t('ui.buttons.resize') }")
                          icon-full-screen
                          span {{ 'ui.buttons.resize' | translate }}
                        template(slot='content')
                            v-card(elevation)
                              v-list-item(v-for="size in resizesView"
                                :key="size.value"
                                @click="selectSize(size)"
                                :class="{active: selectedSize.value === size.value}")
                                v-list-item-content
                                  v-list-item-title
                                    span {{ size.title | translate}}

                  .matrix-cell.row-height--one.p-32.bd-bottom
                    .matrix-item.matrix-item--vertical
                      v-flipable(flip)
                        span.matrix-label.gray {{ (isViewSwapped ? 'pages.skill_gap.achieved_skills' : 'pages.skill_gap.achieved_levels') | translate }}
                    .matrix-item.matrix-item--vertical
                      v-flipable(flip)
                        span.matrix-label.orange {{ (isViewSwapped ? 'pages.skill_gap.required_skills' : 'pages.skill_gap.required_levels') | translate }}
                    .matrix-item.matrix-item--vertical
                      v-flipable(flip)
                        span.m-0.matrix-label {{ (isViewSwapped ? 'pages.skill_gap.skill_gap' : 'pages.skill_gap.level_gap') | translate }}
                        v-info-icon.m-0(:text="(isViewSwapped ? 'pages.skill_gap.skill_gap_description' : 'pages.skill_gap.level_gap_description') | translate")
                  .matrix-cell.bd-bottom
                      matrix-gap-right-list.p-24(
                        :rightLabels="rightLabels"
                        :isViewSwapped="isViewSwapped")
                  .matrix-cell
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MatrixGapRightList from './MatrixGapRightList'
import MatrixGapBottomList from './MatrixGapBottomList'
import IconSwap from '@/components/svg/icons/IconSwap.vue'
import IconFullScreen from '@/components/svg/icons/IconFullScreen.vue'
import IconBadge from '@/components/svg/BadgeNotIndents.vue'
import IconClose from '@/components/svg/skill-matrix/IconClose.vue'
import IconStar from '@/components/svg/icons/IconStar.vue'
import IconCheck from '@/components/svg/skill-matrix/IconCheckMark .vue'
import IconMatrix from '@/components/svg/icons/IconMatrix.vue'

const COLOR_ORANGE = '#E98D2B'
const COLOR_RED = '#F75061'
const COLOR_GREEN = '#13B389'
const COLOR_GREY = '#A0AAC3'

export default {
  name: 'Matrix',

  components: {
    MatrixGapRightList,
    MatrixGapBottomList,
    IconSwap,
    IconMatrix,
    IconFullScreen,
    IconBadge,
    IconClose,
    IconStar,
    IconCheck
  },
  created () {
    this.initView()
  },
  async mounted () {
    await this.init()
    this.loaded = true
    setTimeout(() => this.handleResize(), 100)
  },
  destroyed () {
    this.unmount()
  },

  data: () => ({
    loaded: false,
    isSwappedRowCols: false,
    isViewSwapped: false,
    matrix: [],
    selectedSize: null
  }),

  filters: {
    colorPercent (badge) {
      return !badge.isRequired ? COLOR_GREY : '#252F39'
    },

    iconColor (badge) {
      return +badge.isGoal
        ? COLOR_ORANGE
        : !badge.isRequired
          ? COLOR_GREY
          : (+badge.activeStarsCount < +badge.requiredStarsCount ||
            (!+badge.isActive && +badge.requiredStarsCount === 0))
            ? COLOR_RED
              : COLOR_GREEN
      },

    color (badge) {
      return +badge.isGoal ? COLOR_ORANGE : !badge.isRequired ? COLOR_GREY : COLOR_GREEN
    },

    secondaryColor (badge) {
      return !badge.isRequired ? '#d0d5e1' : COLOR_RED
    },

    backgroundColor (badge) {
      return +badge.isGoal
        ? COLOR_ORANGE
        : !badge.isRequired
        ? COLOR_GREY
        : +badge.activeStarsCount < +badge.requiredStarsCount ||
          (!+badge.isActive && +badge.requiredStarsCount === 0)
        ? COLOR_RED
        : COLOR_GREEN
    },

    addLVL (badge) {
      return `${badge.title} LVL${+badge.required + 1}`
    }
  },

  computed: {
    ...mapGetters('insightsMatrix', ['matrixDetail']),

    badges () {
      return this.matrixDetail.analysis.badges.map(item => ({
        ...item,
        type: 0,
        title: item.name
      }))
    },

    employees () {
      return this.matrixDetail.analysis.employees.map(item => ({
        ...item,
        type: 1,
        title: `${item.firstName} ${item.lastName}`
      }))
    },

    topLabels () {
      return this.isSwappedRowCols ? this.employees : this.badges
    },

    sideLabels () {
      return this.isSwappedRowCols ? this.badges : this.employees
    },

    rightLabels () {
      return this.isSwappedRowCols
        ? this.matrixDetail.analysis.badges
        : this.matrixDetail.analysis.employees
    },

    bottomLabels () {
      return this.isSwappedRowCols
        ? this.matrixDetail.analysis.employees
        : this.matrixDetail.analysis.badges
    },

    isMatrixEmpty () {
      return this.loaded && this.matrix.length === 0
    },
    isCenterContent () {
      return this.selectedSize.value === 'content' && !this.isSwappedRowCols
    },

    matrixID () {
      return +this.$route.params.id
    },

    resizesView () {
      return {
        full: {
          value: 'full',
          title: 'ui.buttons.full_size',
          class: 'full-window'
        },
        normal: {
          value: 'normal',
          title: 'ui.buttons.normal',
          class: 'full-container'
        },
        content: {
          value: 'content',
          title: 'ui.buttons.on_content',
          class: 'full-content'
        }
      }
    },
    matrixViews () {
      return JSON.parse(localStorage.getItem('VIEW_MATRIX')) || []
    }
  },

  methods: {
    ...mapActions('insightsMatrix', ['getMatrixById', 'loadMatrixAnalysis', 'getMatrixById']),

    async init () {
      const data = await this.getMatrixById(this.matrixID)
      if (data) {
        await this.loadMatrixAnalysis(data)
        this.isSwappedRowCols = data.isInverted || false
        this.matrix = []
        this.matrixDetail.analysis.employees.forEach((employee, row) => {
          this.matrix.push([])
          employee.badges.forEach(badge => {
            this.matrix[row].push(badge)
          })
        })
      }
    },

    swapRowsCols () {
      this.isSwappedRowCols = !this.isSwappedRowCols
      this.transformMatrix()
    },

    transformMatrix () {
      this.matrix = this.matrix[0].map((_, colIndex) =>
        this.matrix.map(row => row[colIndex])
      )
    },

    getTooltipText (badge, item) {
      const requiredName = item.levelNames[badge.requiredStarsCount - 1] || ''
      const activeName = item.levelNames[badge.activeStarsCount - 1] || ''
      const caption = item.name || item.title || ''

      return item?.levelNames?.length
              ? `<b>${caption}</b><br/>Required level: ${requiredName}<br/> Active level: ${activeName}`
              : `<b>${caption}`
    },

    renderTooltip (text, numOfWords) {
      return text.length > numOfWords ? text : null
    },

    selectSize (size) {
      this.selectedSize = size
      const data = { matrixID: this.matrixID, value: size.value }
      const arrViews = this.matrixViews
      const idx = this.matrixViews.findIndex(el => el.matrixID === this.matrixID)
      if (idx === -1) {
        arrViews.push(data)
      } else {
        arrViews[idx].value = data.value
      }
      localStorage.setItem('VIEW_MATRIX', JSON.stringify(arrViews))
      setTimeout(() => this.handleResize(), 100)
    },
    initView () {
       let defaultView = this.matrixViews.find(el => el.matrixID === this.matrixID)
        if (defaultView) {
          this.selectSize(this.resizesView[defaultView.value])
        } else {
          this.selectSize(this.resizesView.normal)
        }
        window.addEventListener('scroll', this.handleScroll, true)
        window.addEventListener('resize', this.handleResize, true)
    },
    unmount() {
      window.removeEventListener('scroll', this.handleScroll, true)
      window.removeEventListener('resize', this.handleResize, true)
    },
    handleTableScroll(event) {
      document.getElementById('matrix-header').scrollLeft = event.target.scrollLeft
    },
    handleHeaderScroll(event) {
      document.getElementById('matrix-table').scrollLeft = event.target.scrollLeft
    },
    handleBodyScroll(event) {
      let $body = document.querySelector('body'),
          topPadding = $body.offsetWidth > 1000 ? 169 : 188,
          top = event.target.scrollTop,
          delta = topPadding - top,
          headerTop = delta >= 0 ? delta : 0,
          $header = document.getElementById('matrix-header')

      $header.style.top = headerTop + "px";
    },
    handleScroll(event) {
      if (event.target.id === 'matrix-header') {
        this.handleHeaderScroll(event)
      } else if (event.target.id === 'matrix-table') {
        this.handleTableScroll(event)
      } else {
        this.handleBodyScroll(event)
      }
    },
    handleResize() {
      let $middleColumn = document.getElementsByClassName('matrix-column')[1],
          $header = document.getElementById('matrix-header')

      if ($header) {
          $header.style.left = $middleColumn.offsetLeft + 'px'
          $header.style.width = $middleColumn.offsetWidth + 'px'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#matrix {
  &-header {
    position: fixed;
    top: 169px;
    left: 430px;
    background: #fff;
    z-index: 1;
    min-width: auto !important;
    overflow-x: scroll;
    justify-content: flex-start;
    padding: 34px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-left: 1px solid #D8DAE3;
    border-right: 1px solid #D8DAE3;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-content {
    padding-top: 425px;
  }
}

@media screen and (max-width: 1000px) {
  #matrix-header {
    top: 188px;
    border-top: 1px solid #D8DAE3;
  }
}

.full-window {
  max-width: 100%;
}
.full-container {
  max-width: 1240px;
}
.full-content {
  max-width: fit-content;
}
.h-400 {
  height: 400px;
}

.matrix {
  border: 1px solid $color-line-dotted;
  overflow: hidden;
  .w-fc {
    min-width: 100%;
    width: fit-content;
  }

  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }

  &-row {
    display: flex;
    &.circle {
      gap: 16px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &-column {
    display: flex;
    flex-direction: column;

    &:nth-child(1) {
      width: 408px;
    }
    &:nth-child(2) {
      width: calc(100% - 408px - 234px);
      border-left: 1px solid $color-line-dotted;
      border-right: 1px solid $color-line-dotted;
    }
    &:nth-child(3) {
      width: 234px;
    }
  }

  &-scrollable {
    overflow-x: auto;
  }
  .row-height--one {
    height: calc(400px - 56px);
  }

  &-cell {
    display: flex;
    &--column {
      flex-direction: column;
    }
    &--center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-title {
    color: $h2-title;
    display: flex;
    align-items: center;

    h1 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
    }

    svg {
      height: 16px;
      width: 16px;
      margin-right: 16px;
    }
  }

  &-baguette {
    gap: 8px;
    padding: 8px;
    min-width: auto;
    svg {
      color: $color-dark-green;
      margin: 0;
    }
    span {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    padding: 20px 16px 19px 16px;
    height: 56px;
    .matrix-btn--icon{
      span{
       max-width: 70px;
       text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
     }
    }
    ::v-deep .v-dropdown {
      .v-dropdown__toggler {
        display: flex;
      }
      .v-list__item {
        &.active {
          .v-list__item-title {
            color: $color-primary;
          }
        }
      }
      .v-list__item-title {
        white-space: nowrap;
      }
    }
  }

  &-btn--icon {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $body-text;
    text-decoration: underline;

    ::v-deep .v-btn__content,
    .v-flipable {
      gap: 8px;
      span{
       max-height: 70px;
       text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
     }
    }

    svg {
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }
  .bd-bottom {
    border-bottom: 1px solid $color-line-dotted;
  }
  .bd-right {
    border-right: 1px solid $color-line-dotted;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .v-flipable {
      gap: 8px;
    }
    ::v-deep .v-info-icon {
      color: $body-text-2;
    }

    &--vertical {
      height: 100%;
      align-items: flex-end;

      &:not(:last-child) {
        margin-right: 32px;
      }
    }

    &--horizontal {
      width: 100%;
      justify-content: flex-end;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
      &.m-32 {
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
  }

  &-label {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    color: $body-text;
    &.gray {
      color: $color-inactive-text;
    }
    &.orange {
      color: $color-orange;
    }
  }

  .employee {
    &-item {
      display: flex;
      &--vertical {
        align-items: flex-end;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
      &--horizontal {
        justify-content: flex-end;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
    &-user {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      font-size: 14px;
      line-height: 16px;
      color: $body-text;
      text-align: right;
      &--name {
        font-weight: 700;
        margin-bottom: 4px;
      }
      &--position {
        font-weight: 400;
      }
    }
  }

  ::v-deep .v-donut {
    width: 32px !important;
    height: 32px !important;
  }

  .p-24-24-8-24 {
    padding: 24px 24px 8px 24px;
  }
  .p-24 {
    padding: 24px;
  }
  .p-20 {
    padding: 20px;
  }
  .p-20-16 {
    padding: 20px 16px;
  }
  .p-32 {
    padding: 32px;
  }
  .p-20-24 {
    padding: 20px 24px;
  }
  .p-12 {
    padding: 12px;
  }

  .badge-item {
    &--title {
      font-size: 16px;
      line-height: 16px;
      color: $body-text;
      font-weight: 700;
    }

    &--icon {
      width: 16px;
      height: 16px;
      color: $color-orange;
    }
  }
}
</style>
