<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.42691 13.2529L13.6241 16L12.6218 10.2088L16.8538 6.12529L11.0255 5.27146L8.42691 0L5.82831 5.27146L0 6.12529L4.23202 10.2088L3.2297 16L8.42691 13.2529Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconStar'
}
</script>

<style>
</style>
