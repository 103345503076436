
<template functional>
  <svg
    :class="[data.class, data.staticClass]"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.01"
      x="0.199951"
      y="0.199951"
      width="17.6"
      height="17.6"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8145 15.8144C15.5138 16.1151 15.0263 16.1151 14.7256 15.8144L2.18557 3.27443C1.88486 2.97373 1.88486 2.48619 2.18557 2.18549C2.48627 1.88478 2.97381 1.88478 3.27451 2.18549L15.8145 14.7254C16.1152 15.0261 16.1152 15.5137 15.8145 15.8144Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8144 2.18549C16.1151 2.48619 16.1151 2.97373 15.8144 3.27443L3.27443 15.8144C2.97373 16.1151 2.48619 16.1151 2.18549 15.8144C1.88479 15.5137 1.88479 15.0262 2.18549 14.7255L14.7255 2.18549C15.0262 1.88479 15.5137 1.88479 15.8144 2.18549Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClose'
}
</script>

<style scoped></style>
