<template functional>
<svg :class="[data.class, data.staticClass]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02318 16C9.12677 15.2019 9.96487 14.5849 11.5919 13.6068C12.3651 13.1396 12.9871 12.4533 13.3818 11.6322C13.776 10.8491 13.9875 9.98444 14 9.10503V1.10804L8.00654 0L2 1.10804V9.10583C2.01249 9.98524 2.224 10.8499 2.61817 11.633C3.01288 12.454 3.63479 13.1402 4.40769 13.6076C6.68064 14.9712 6.86887 15.2152 8.02318 16Z" fill="currentColor"/>
</svg>

</template>
<script>
export default {
  name: 'BadgeNotIndents'
}
</script>
