<template functional>
<svg :class="[data.class, data.staticClass]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33331 2C1.33331 1.63181 1.63179 1.33333 1.99998 1.33333H4.99998C5.26962 1.33333 5.51271 1.49576 5.6159 1.74488C5.71909 1.99399 5.66205 2.28074 5.47138 2.4714L4.4527 3.49009L5.80234 4.82623C6.064 5.08527 6.06612 5.50737 5.80708 5.76903C5.54804 6.03068 5.12594 6.0328 4.86428 5.77377L3.50988 4.43291L2.47138 5.4714C2.28072 5.66207 1.99397 5.71911 1.74486 5.61592C1.49574 5.51273 1.33331 5.26964 1.33331 5V2ZM10.3841 1.74488C10.4872 1.49576 10.7303 1.33333 11 1.33333H14C14.3682 1.33333 14.6666 1.63181 14.6666 2V5C14.6666 5.26964 14.5042 5.51273 14.2551 5.61592C14.006 5.71911 13.7192 5.66207 13.5286 5.4714L12.4833 4.42614L11.1381 5.7714C10.8777 6.03175 10.4556 6.03175 10.1952 5.7714C9.93489 5.51105 9.93489 5.08894 10.1952 4.82859L11.5405 3.48333L10.5286 2.4714C10.3379 2.28074 10.2809 1.99399 10.3841 1.74488ZM5.80708 10.1976C6.06612 10.4593 6.064 10.8814 5.80234 11.1404L4.43595 12.4932L5.47138 13.5286C5.66205 13.7193 5.71909 14.006 5.6159 14.2551C5.51271 14.5042 5.26962 14.6667 4.99998 14.6667H1.99998C1.63179 14.6667 1.33331 14.3682 1.33331 14V13.9669C1.33331 13.9668 1.33331 13.9666 1.33331 13.9664V11C1.33331 10.7304 1.49574 10.4873 1.74486 10.3841C1.99397 10.2809 2.28072 10.3379 2.47138 10.5286L3.49313 11.5503L4.86428 10.1929C5.12594 9.93386 5.54804 9.93598 5.80708 10.1976ZM10.2286 10.1953C10.4889 9.93491 10.911 9.93491 11.1714 10.1953L12.5166 11.5405L13.5286 10.5286C13.7192 10.3379 14.006 10.2809 14.2551 10.3841C14.5042 10.4873 14.6666 10.7304 14.6666 11V13.966C14.6666 13.9664 14.6666 13.9669 14.6666 13.9673V14C14.6666 14.3682 14.3682 14.6667 14 14.6667H11C10.7303 14.6667 10.4872 14.5042 10.3841 14.2551C10.2809 14.006 10.3379 13.7193 10.5286 13.5286L11.5738 12.4833L10.2286 11.1381C9.96823 10.8777 9.96823 10.4556 10.2286 10.1953Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'IconFullScreen'
}
</script>
