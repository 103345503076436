<template lang="pug">
.matrix-cell.matrix-cell--column
  matrix-gap-right-list-item(
    v-for="(employee, index) in rightLabels",
    :key="index",
    :data="mutationData(employee)"
  )
</template>

<script>
import MatrixGapRightListItem from './MatrixGapRightListItem'

export default {
  name: 'MatrixGapRightList',

  props: {
    rightLabels: Array,
    isViewSwapped: Boolean
  },

  components: {
    MatrixGapRightListItem
  },

  methods: {
    mutationData ({ gaps }) {
      return !this.isViewSwapped
        ? {
            achieved: gaps.achievedLevelsCount,
            required: gaps.requiredLevelsCount,
            gap: gaps.levelGap
          }
        : {
            achieved: gaps.achievedSkillsCount,
            required: gaps.requiredSkillsCount,
            gap: gaps.skillGap
          }
    }
  }
}
</script>

<style>
</style>
